<template>
	<div>

		<!-- 凭证界面 -->
		<el-dialog title="凭证列表" top="1vh" width="90%" :visible.sync="is_show_in_Page">
			<div class="voucher">
				<div class="header">
					<el-button type="primary" @click="voucher_add">上传</el-button>
				</div>
				<div class="list" v-if="list.length>0">
					<!-- v-if="voucher_item.could_del" -->
					<div v-for="(voucher_item,index) in list" :key="index" style="width:19%;margin-right: 20px;">
						<div class="item" >
						<div class="del_btn" v-if="voucher_item.could_del"  @click="voucher_del(index)">×</div>
						 <el-image
						    :z-index=10000 
							class="img"
							:src="voucher_item.src"
							:previewSrcList="voucher_item.src_enlarge"
							>
						</el-image>
						<div class="text">{{voucher_item.mark}}({{voucher_item.type_text}})</div>
					</div>
	                   <div class="file_num">磅单编号:{{voucher_item.file_num}}</div>
						<div class="button"><el-button type="primary" @click="voucher_download(voucher_item.src)">下载</el-button></div>
					</div>
				
				</div>
				<div class="list_none" v-else>
					无数据
				</div>
			</div>
		</el-dialog>


		<!-- 凭证添加界面 -->
		<el-dialog title="添加凭证" top="2vh" width="500px" :visible.sync="upl.is_show">
			<div class="upl">
				<div class="img_area" @click="voucher_add_sub">
					<img v-if="upl.src" :src="upl.src" class="show">
					<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>
				<textarea placeholder="凭证备注" class="mark_area" v-model="upl.mark"></textarea>
				<div class="btn_area">
					<el-button type="primary" @click="voucher_sub">上传</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
import {mapState} from 'vuex'
	export default {
		props:{
			truck_tord_num:String,//运单编号
			is_show:Number,//是否显示
		},
		data() {
			return {
				//这些子账户开启删除凭证功能
				not_pay_tels:['15993760560','17539199919','18336335275','13723198172','18855370372',],
				//列表是否显示
				is_show_in_Page:false,

				//运单编号
				truck_tord_num_in_Page:'',

				//凭证列表
				list:[],

				//上传界面
				upl:{

					//是否显示上传界面
					is_show:false,

					//地址
					src:'',

					//备注
					mark:''
				}
			}
		},
		watch:{
			truck_tord_num(new_data){
				this.truck_tord_num_in_Page=new_data
			},
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		computed:{
			...mapState(['user_info'])
		},
		methods: {
			//凭证下载
			 voucher_download(url) {
				
				let image = new Image();
				image.setAttribute('crossOrigin', 'anonymous');
				image.src = url;
				image.onload = () => {
					let canvas = document.createElement('canvas');
					canvas.width = image.width;
					canvas.height = image.height;
					let ctx = canvas.getContext('2d');
					ctx.drawImage(image, 0, 0, image.width, image.height);
					canvas.toBlob((blob) => {
					let url = URL.createObjectURL(blob);
					this.download(url,'运单凭证');
					// 用完释放URL对象
					URL.revokeObjectURL(url);
					});
				};
				},
				download(href) {
					let eleLink = document.createElement('a');
					eleLink.download = '运单凭证';
					eleLink.href = href;
					eleLink.click();
					eleLink.remove();
			},
			//初始化
			init(){

				//如果没有订单编号则不读取数据
				if(!this.truck_tord_num_in_Page){
					return;
				}

				//读取数据
				this.load_data()
			},


			//删除凭证
			voucher_del(index){

				//询问
				this.$my.other.confirm({
					content:'确定删除凭证',
					confirm:()=>{
						
						//执行删除
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_voucher_del',
								voucher_id:this.list[index].voucher_id,
							},
							callback:(data)=>{

								//刷新数据
								this.load_data()
							}
						});
					}
				});
			},


			//凭证相关操作
			voucher_sub(){
				
				if(!this.upl.src){
					this.$my.other.msg({
						type:'warning',
						str:"未上传图片"
					});
					return;
				}
				
				//上传
				this.$my.qiniu.upl({
					bucket: 'trucktordrealtimevoucher',
					file_path: this.upl.src,
					callback: (data) => {
						var key = data.key.split('__v__1')
						//上传
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_voucher_add_by_shipper',
								truck_tord_num:this.truck_tord_num_in_Page,
								img_key:key[0],
								mark:this.upl.mark
							},
							callback:(data)=>{
								
								//关闭添加凭证界面
								this.upl.is_show=false;

								//刷新数据
								this.load_data()
							}
						});
					}
				});
			},
			voucher_add(){//打开添加凭证界面

				//打开上传界面
				this.upl.is_show=true;

				//清空上次上传的图片
				this.upl.src=''

				//清空备注
				this.upl.mark=''
			},
			voucher_add_sub(){//提交凭证

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {

						//预览
						this.upl.src=res.src
					}
				});
			},


			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_voucher_list',
						truck_tord_num:this.truck_tord_num_in_Page
					},
					callback:(data)=>{
						//构造数据
						let list=[];
						for(var item of data.list){
							//上传人
							switch(item.upl_user_type){
								case '1':var upl_type_text="货主上传";break;
								case '2':var upl_type_text="司机上传";break;
								case '3':var upl_type_text="后台上传";break;
								case '4':var upl_type_text="货主子账号上传";break;
								case '5':var upl_type_text="网点上传";break;
							}
							switch(item.type){
								case '1':var type_text="磅单";break;
								case '2':var type_text="回单";break;
							}
							list.push({
								type_text:type_text,
								file_num:item.file_num,
								voucher_id:item.id,
								could_del:item.upl_user_type==3,
								src:this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key),
								src_enlarge:[this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key)],
								mark:item.mark?upl_type_text+"(备注:"+item.mark+")":upl_type_text
							})
						}
						this.list=list
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.upl{
		.img_area{
			border:1px solid #ccc;
			cursor: pointer;
			padding: 5px;
			.show{
				margin: auto;
				display: block;
				max-width: 100%;
				max-height: 100%;
			}
			.icon{
				text-align: center;
				display: block;
				font-size: 100px;
				height: 500px;
				line-height: 500px;
			}
		}
		.mark_area{
			border: 1px solid #ccc;
			padding: 5px;
			width: 448px;
			margin-top: 10px;
			height: 100px;
			outline: none;
			font-size: 16px;
		}
		.mark_area::placeholder {
			font-size: 20px;
			line-height: 100px;
			text-align: center; 
		}
		.btn_area{
			text-align: center;
			margin-top: 15px;
		}
	}

	.voucher{
		margin-top: -10px;
		.header{
			padding-bottom: 20px;
			border-bottom: 1px solid #ccc;
		}
		.list_none{
			height:300px;
			line-height:300px;
			text-align:center;
			font-size: 18px;
		}
		.list{
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 30px;
			.item{
				margin: 15px 0.4% 0 0.4%;
				width: 100%;
				height: 19vw;
				position: relative;
				border: 1px solid #ccc;
				border-radius: 5px;
				.del_btn{
					font-size: 20px;
					width: 40px;
					height: 40px;
					z-index: 1000;
					line-height: 40px;
					text-align: center;
					border-radius: 20px;
					color: #fff;
					background-color: #000;
					opacity: 0.5;
					position: absolute;
					right: -10px;
					top: -10px;
					cursor: pointer;
				}
				.img{
					max-width: 95%;
					max-height: calc( 100% - 20px - 1vw);
					display: block;
					margin: 0.3vw auto 0 auto;
				}
				.text{
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
			}
		}
		.file_num{
			margin-top: 10px;
			text-align: center;
		}
		.button{
			text-align: center;
			margin-top: 10px;
		}
	}
</style>